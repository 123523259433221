import moment from "moment";
import { PriceHelper } from "../../helpers";

export default class Client {
    id;
    website;
    active;
    status;
    language;
    first_name;
    last_name;
    email;
    birth_date;
    gender;
    phone;
    national_insurance_number;
    billing_street;
    billing_number;
    billing_box;
    billing_postal;
    billing_city;
    billing_country;
    delivery_equal;
    delivery_street;
    delivery_number;
    delivery_box;
    delivery_postal;
    delivery_city;
    delivery_country;

    iban;
    bic;
    webshop_discount_code;

    membership = {
        enabled: false,
        price: 0,
        paid: false,
        expires: null
    };

    consent_1;
    consent_2;
    consent_3;

    created_at
    email_verified_at;
    last_login;

    static formJson = (json) => {
        let item = new Client();

        item.website = json.website;
        item.module = json.module;

        item.id = json.id;
        item.internal_id = json.internal_id;
        item.active = json.active;
        item.status = json.status;

        item.language = json.language;

        item.first_name = json.first_name;
        item.last_name = json.last_name;
        item.email = json.email

        item.gender = json.gender || '';
        item.birth_date = json.birth_date || '';
        item.national_insurance_number = json.national_insurance_number || '';
        item.phone = json.phone || '';

        item.billing_street = json.billing_street || '';
        item.billing_number = json.billing_number || '';
        item.billing_box = json.billing_box || '';
        item.billing_postal = json.billing_postal || '';
        item.billing_city = json.billing_city || '';
        item.billing_country = json.billing_country || '';

        item.delivery_equal = json.delivery_equal;
        item.delivery_street = json.delivery_street || '';
        item.delivery_number = json.delivery_number || '';
        item.delivery_box = json.delivery_box || '';
        item.delivery_postal = json.delivery_postal || '';
        item.delivery_city = json.delivery_city || '';
        item.delivery_country = json.delivery_country || '';

        item.iban = json.iban || '';
        item.bic = json.bic || '';
        item.webshop_discount_code = json.webshop_discount_code || '';

        item.membership = {
            enabled: json.membership_enabled,
            price: PriceHelper.price(json.membership_price ? json.membership_price : 0),
            paid: json.membership_paid ? moment(json.membership_paid) : null,
            expires: json.membership_expires ? moment(json.membership_expires) : null
        };

        item.consent_1 = !!json.consent_1;
        item.consent_2 = !!json.consent_2;
        item.consent_3 = !!json.consent_3;

        item.created_at = moment(json.created_at);
        item.email_verified_at = json.email_verified_at ? moment(json.email_verified_at) : null;
        item.last_login = json.last_login ? moment(json.last_login) : null;

        return item;
    }

    fullname = () => this.first_name + ' ' + this.last_name;
    statusLabel = () => {
        if (this.status.indexOf('membership-') < 0) {
            if (this.status === 'active') {
                return { color: 'success', label: 'CMS.Client.overview.table.status.active', icon: 'fa-check', extra: [] };
            }
            return { color: 'danger', label: 'CMS.Client.overview.table.status.inactive', icon: 'fa-times', extra: ['email'] };
        }

        if (this.status === 'membership-paid') {
            return { color: 'success', label: 'CMS.Client.overview.table.status.membership_active', icon: 'fa-check', extra: ['expires'] };
        }

        if (!this.membership.expires) {
            return { color: 'warning', label: 'CMS.Client.overview.table.status.membership-awaiting-payment', icon: 'fa-stopwatch', extra: ['pay-manual'] };
        }

        if (this.membership.expires > moment()) {
            return { color: 'warning', label: 'CMS.Client.overview.table.status.membership-awaiting-payment-renewal', icon: 'fa-stopwatch', extra: ['expires', 'pay-manual'] };
        }

        return { color: 'danger', label: 'CMS.Client.overview.table.status.membership_inactive', icon: 'fa-times', extra: ['expired'] };
    }
}
