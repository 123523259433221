import React from "react";
import { injectIntl } from "react-intl";
import AttendeeRepository from "../../repository/AttendeeRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import AttendeeTextFormComponent from "./forms/AttendeeTextFormComponent";
import Module from "../../models/module/Module";
import Attendee from "../../models/attendee/Attendee";
import { ClientRepository, DynamicFieldValueRepository, ModuleRepository, WebsiteRepository } from "../../repository";
import { LoaderComponent } from "../basics/layout";
import Website from "../../models/website/Website";
import { CountryHelper } from "../../helpers";
import * as Yup from "yup"

class AttendeeFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new Attendee(),
            loading: true,
            settings: {},
            isEdit: false,
            roles: this.props.user.roles,
        };

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings(),
            WebsiteRepository.get(Website.get()),
            ClientRepository.all(),
            DynamicFieldValueRepository.all(1),
            DynamicFieldValueRepository.all(2),
            DynamicFieldValueRepository.all(3)
        ];

        if (this.props.get_promise !== undefined) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }

                if (result[2] !== undefined) {
                    let clients = [];
                    result[2].forEach(c => clients.push({ value: c.id, label: c.fullname() }))
                    settings.clients = clients;
                }

                [1, 2, 3].forEach(i => {
                    if (settings['show_additional_dynamic_field_' + i] && settings['additional_dynamic_field_' + i + '_type'] !== 'dropdown') return;
                    settings['additional_dynamic_field_' + i + '_options'] = result[2 + i];
                });

                let model = this.state.model;
                if (result[6] !== undefined) {
                    model = Attendee.formJson(result[6].attendee);
                }

                if (result[1] !== undefined) {
                    if (!model.language) model.language = result[1].default_language;

                    let languages = [];
                    result[1].languages.forEach(language => {
                        languages.push({ value: language.language, label: CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label })
                    });
                    settings.languages = languages;
                }

                this.setState({
                    loading: false,
                    settings: settings,
                    model: model,
                    isEdit: model.id
                });
            });
    }

    schema = () => {
        let { settings } = this.state;

        let fields = {
            client: Yup.string().required('CMS.Attendee.Content.Form.client'),
            first_name: Yup.string().required('CMS.Attendee.Content.Form.first_name').max(100, 'CMS.Attendee.Content.Form.first_name|100|max').matches(/^[A-z\u00C0-\u00ff' -]+$/, 'CMS.Attendee.Content.Form.first_name|letter', { excludeEmptyString: true }),
            last_name: Yup.string().required('CMS.Attendee.Content.Form.last_name').max(100, 'CMS.Attendee.Content.Form.last_name|100|max').matches(/^[A-z\u00C0-\u00ff' -]+$/, 'CMS.Attendee.Content.Form.first_name|letter', { excludeEmptyString: true })
        };

        if (settings.fields?.email) {
            fields.email = Yup.string().required('CMS.Attendee.Content.Form.email').email('CMS.Attendee.Content.Form.email|invalid').max(100, 'CMS.Attendee.Content.Form.email|100|max');
        }
        if (settings.fields?.birth_date) {
            fields.birth_date = Yup.date().max(new Date(), 'CMS.Attendee.Content.Form.birth_date|max_date').nullable();
        }
        if (settings.fields?.national_insurance_number) {
            fields.national_insurance_number = Yup.string().max(50, 'CMS.Attendee.Content.Form.national_insurance_number|50|max').nullable();
        }

        if (settings.fields?.phone) {
            fields.phone = Yup.string().phone('BE', 'CMS.Attendee.Content.Form.phone|phone').nullable();
        }

        if (settings.show_additional_dynamic_field_1 && settings.additional_dynamic_field_1_required) {
            fields.additional_dynamic_field_1 = Yup.string().max(100, settings.additional_dynamic_field_1_title + '|100|max').nullable();
        }
        if (settings.show_additional_dynamic_field_2 && settings.additional_dynamic_field_2_required) {
            fields.additional_dynamic_field_2 = Yup.string().max(100, settings.additional_dynamic_field_2_title + '|100|max').nullable();
        }
        if (settings.show_additional_dynamic_field_3 && settings.additional_dynamic_field_3_required) {
            fields.additional_dynamic_field_3 = Yup.string().max(100, settings.additional_dynamic_field_3_title + '|100|max').nullable();
        }

        return Yup.object().shape(fields);
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                ref={this.table_ref}
                parent={this}
                className="attendee"
                validationSchema={this.schema}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    if (this.state.isEdit) {
                        AttendeeRepository.update({ id: values.id, ...values })
                            .then(() => {
                                FormToastComponent.successTrans("Attendee", "Default.saved");
                                this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                            })
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    } else {
                        AttendeeRepository.create({ ...values, ...{ source: 'console' } })
                            .then(() => {
                                FormToastComponent.successTrans("Attendee", "Default.saved");
                                this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                            })
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    }
                }}
                formErrors={this.state.form_errors}
                fields={AttendeeTextFormComponent.fields(this.state.settings, this)}
            />
        );
    }
}

export default withRouter(injectIntl(AttendeeFormComponent));