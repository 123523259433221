import Attendee from "../models/attendee/Attendee";
import API from "../services/api";

export default class AttendeeRepository {
    static create = (data) => API.post('/attendee/create', data);
    static update = (data) => API.post('/attendee/update', data).then(response => response.data);
    static quickUpdate = (data) => API.post('/attendee/quick-update', data).then(response => Attendee.formJson(response.data.attendee));
    static delete = (id) => API.delete('/attendee/delete?id=' + id);

    static all = (all) => API.get('/attendee/all?all=' + (all ? 1 : 0)).then(response => response.data.attendees.map(attendee => Attendee.formJson(attendee.attendee)));
    static get = (id) => API.get('/attendee/details/' + id).then(response => response.data);

    static notify = (data) => API.post('/attendee/notify', data).then(response => response.data);

    static toggle = (id) => API.post('/attendee/toggle', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.active;
            }
        });

    static import = (file, module) => {
        const data = new FormData();
        data.append('file', file);
        if (module) data.append('module_id', module?.id);

        return new Promise((resolve, reject) => {
            API.post('/attendee/import', data).then(response => resolve(response)).catch(response => reject(response.response.data.errors))
        });
    };
}