import React from "react";
import { DeleteModalComponent, StandardTableComponent, TableEditActionComponent, TableDeleteActionComponent } from "../basics/table";
import { Button } from "react-bootstrap";
import { CheckPermissions, GeneralSettingsHelper } from "../../helpers";
import UserRolesEnum from "../../enums/UserRolesEnum";
import { AttendeeRepository } from "../../repository";
import FormToastComponent from "../basics/FormToastComponent";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../basics/FormattedMessageString";
import Module from "../../models/module/Module";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderComponent from "../basics/layout/LoaderComponent";
import moment from "moment";
import TableDropdownActionComponent from "../basics/table/TableDropdownActionComponent";
import ToolTipLink from "../basics/ToolTipLink";

class AttendeeOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            settings: Module.getSettings(),
            isLoading: false
        };
    }

    columns = () => {
        let columns = [
            {
                dataField: 'id',
                hidden: true
            },
            {
                dataField: 'first_name',
                text: <FormattedMessageString id="CMS.Attendee.overview.table.first_name" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.first_name
            },
            {
                dataField: 'last_name',
                text: <FormattedMessageString id="CMS.Attendee.overview.table.last_name" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.last_name
            }];

        if (this.state.settings.fields?.email) {
            columns = [...columns, ...[{
                dataField: 'email',
                text: <FormattedMessageString id="CMS.Attendee.overview.table.email" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.email
            }]];
        }

        if (this.state.settings.fields?.birth_date) {
            columns = [...columns, ...[{
                dataField: 'birth_date',
                text: <FormattedMessageString id="CMS.Attendee.overview.table.birth_date" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.birth_date ? moment(row.birth_date).format('DD/MM/YYYY') : '-'
            }]];
        }

        columns = [...columns, ...[{
            dataField: 'client',
            text: <FormattedMessageString id="CMS.Attendee.overview.table.client" />,
            sort: true,
            isDummyField: true,
            formatter: (cellContent, row) => row.client?.name
        }]];

        if (this.state.settings.use_membership) {
            columns = [
                ...columns, ...[
                    /*{
                        dataField: 'membership_price',
                        text: <FormattedMessageString id="CMS.Attendee.overview.table.membership_price" />,
                        sort: true,
                        isDummyField: true,
                        formatter: (cellContent, row) => '€ ' + row.membership.price
                    },*/
                    {
                        dataField: 'status',
                        text: <FormattedMessageString id="CMS.Attendee.overview.table.status" />,
                        sort: true,
                        isDummyField: true,
                        formatter: (cellContent, row) => {
                            let status = row.statusLabel();

                            return (
                                <>
                                    <FormattedMessage id={status.label}>
                                        {value => <div className={'badge badge-' + status.color}>
                                            <FontAwesomeIcon icon={['fa', status.icon]} />
                                            {value}
                                        </div>}
                                    </FormattedMessage>

                                    <div>
                                        {status.extra.indexOf('expires') > -1 && (
                                            <>
                                                <FormattedMessage id="CMS.Client.overview.table.status.membership_expires" values={{ date: row.membership.expires.format('DD/MM/YYYY') }}>
                                                    {value => <span className="text-muted">{value}</span>}
                                                </FormattedMessage>
                                                <br />
                                            </>
                                        )}
                                        {status.extra.indexOf('expired') > -1 && (
                                            <>
                                                <FormattedMessage id="CMS.Client.overview.table.status.membership_expired" values={{ date: row.membership.expires.format('DD/MM/YYYY') }}>
                                                    {value => <span className="text-muted">{value}</span>}
                                                </FormattedMessage>
                                                <br />
                                            </>
                                        )}
                                        {status.extra.indexOf('pay-manual') > -1 && (
                                            <Button variant="link" type="button" size="sm" onClick={() => this.setMembershipPaid(row)}>
                                                <FormattedMessage id="CMS.Client.overview.table.status.membership_set_paid">
                                                    {value => value}
                                                </FormattedMessage>
                                            </Button>
                                        )}
                                        {status.extra.indexOf('email') > -1 && (
                                            <FormattedMessage id="CMS.Client.overview.table.status.membership_email_not_confirmed">
                                                {value => <span className="text-muted">{value}</span>}
                                            </FormattedMessage>
                                        )}
                                    </div>
                                </>
                            )
                        }
                    }
                ]
            ]
        }

        columns = [...columns, ...[{
            dataField: 'actions',
            isDummyField: true,
            text: <FormattedMessage id="CMS.Table.actions" />,
            hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
            sort: false,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            },
            formatter: (cellContent, row) => {
                let mailActions = [];

                if (row.status === 'membership-awaiting-payment') {
                    mailActions.push({
                        key: 'payment_instructions',
                        icon: 'receipt',
                        label: 'CMS.Attendee.overview.table.notification.payment_instructions',
                        callback: () => this.notify(row.id, row.membership.expires ? 'payment-instructions-renewal' : 'payment-instructions')
                    });
                }

                return <div className="actions">
                    {this.state.settings.use_membership && (
                        <ToolTipLink title={'CMS.Client.overview.table.status.membership_enabled.' + (row.membership.enabled ? 'true' : 'false')} onClick={() => this.quickUpdate(row, { action: 'toggle-membership-enabled' })}>
                            <FontAwesomeIcon className={row.membership.enabled ? 'succcess' : 'danger'} icon={['far', 'fa-arrows-repeat']} />
                        </ToolTipLink>
                    )}
                    {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && mailActions.length > 0 && (
                        <TableDropdownActionComponent id={row.id} icon="envelope" items={mailActions} />
                    )}
                    {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                        row.active ?
                            <Link onClick={() => this.toggleActive(row)} className="active" to="#">
                                <FontAwesomeIcon icon={['fas', 'check']} />
                            </Link>
                            :
                            <Link onClick={() => this.toggleActive(row)} className="inactive" to="#">
                                <FontAwesomeIcon icon={['fas', 'times']} />
                            </Link>
                    )}
                    {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                        <TableEditActionComponent id={row.id} custom={''} />
                    )}
                    {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                        <TableDeleteActionComponent row={row} parent={this} />
                    )}
                </div >
            }
        }]];

        return columns;
    }

    updateData = () => AttendeeRepository.all().then(response => this.props.parent.setState({ rows: response }));
    notify = (id, notification) => AttendeeRepository.notify({ id: id, notification: notification }).then(() => FormToastComponent.successTrans('Attendee', 'CMS.Attendee.overview.table.notification.payment_instructions.sent'))

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className={"custom-data-table attendee"}>
                <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns()} search={true}
                    title={this.props.title_id} type={this.props.type}
                    subRows={false}
                    sort={{ dataField: 'first_name', order: 'asc' }}
                />
                <DeleteModalComponent parent={this} />
            </div>
        );
    }

    toggleActive = (_row) => AttendeeRepository.toggle(_row.id)
        .then(response => {
            let newRows = [...this.state.rows];
            newRows = newRows.map((row) => {
                if (row.id === _row.id) {
                    row.active = response;
                }
                return row;
            });
            this.setState(curr => ({ ...curr, rows: newRows, reload: true }), () => {
                this.setState({ reload: false })
            });
        })
        .catch(() => FormToastComponent.errorTrans('CMS.Attendee.overview.toggle.failed'));

    quickUpdate = (_row, data) => AttendeeRepository.quickUpdate({ id: _row.id, action: data.action, value: null })
        .then(response => this.setState(curr => ({ ...curr, rows: [...this.state.rows].map(row => row.id === _row.id ? response : row), reload: true }), () => this.setState({ reload: false })))
        .catch(error => FormToastComponent.errorTrans('CMS.Client.overview.toggle.failed'));

    setMembershipPaid = (_row) => AttendeeRepository.quickUpdate({ id: _row.id, action: 'status', value: 'membership-paid' })
        .then(response => this.setState(curr => ({ ...curr, rows: [...this.state.rows].map(row => row.id === _row.id ? response : row), reload: true }), () => {
            this.setState({ reload: false })
        }))
        .catch(() => FormToastComponent.errorTrans('CMS.Attendee.overview.toggle.failed'));
}

export default AttendeeOverviewTableComponent;
