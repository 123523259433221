import React from 'react';
import FormattedMessageString from "../../basics/FormattedMessageString";
import ClientFormError from "../errors/ClientFormError";
import { CountryHelper } from '../../../helpers';

class ClientTextFormComponent {
    static fields(settings, model) {
        let fields = [
            {
                type: "col",
                md: 6,
                fields: [
                    {
                        title: "CMS.Client.Content.Form.language",
                        type: "select",
                        options: settings.languages,
                        name: 'language',
                        customFeedback: ClientFormError,
                        formErrorName: "language"
                    }
                ]
            },
            {
                type: "col",
                md: 6,
                fields: []
            },
            {
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.first_name',
                        type: "text",
                        name: "first_name",
                        customFeedback: ClientFormError,
                        formErrorName: "first_name"
                    }
                ]
            },
            {
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.last_name',
                        type: "text",
                        name: "last_name",
                        customFeedback: ClientFormError,
                        formErrorName: "last_name"
                    }
                ]
            },
            {
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.email',
                        type: "email",
                        name: "email",
                        customFeedback: ClientFormError,
                        formErrorName: "email"
                    }
                ]
            },
            {
                type: "col",
                md: 6,
                fields: []
            }
        ];

        if (settings.fields.phone) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.phone',
                        type: "text",
                        name: "phone",
                        optional: true,
                        customFeedback: ClientFormError,
                        formErrorName: "phone"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] });
        }

        if (settings.fields.billing_address) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.billing_street',
                        type: "text",
                        name: "billing_street",
                        optional: true,
                        customFeedback: ClientFormError,
                        formErrorName: "billing_street"
                    }
                ]
            });
            fields.push({
                type: "col",
                md: 3,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.billing_number',
                        type: "text",
                        name: "billing_number",
                        optional: true,
                        customFeedback: ClientFormError,
                        formErrorName: "billing_number"
                    }
                ]
            });
            fields.push({
                type: "col",
                md: 3,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.billing_box',
                        type: "text",
                        name: "billing_box",
                        optional: true,
                        customFeedback: ClientFormError,
                        formErrorName: "billing_box"
                    }
                ]
            });
            fields.push({
                type: "col",
                md: 4,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.billing_postal',
                        type: "text",
                        name: "billing_postal",
                        optional: true,
                        customFeedback: ClientFormError,
                        formErrorName: "billing_postal"
                    }
                ]
            });
            fields.push({
                type: "col",
                md: 4,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.billing_city',
                        type: "text",
                        name: "billing_city",
                        optional: true,
                        customFeedback: ClientFormError,
                        formErrorName: "billing_city"
                    }
                ]
            });
            fields.push({
                type: "col",
                md: 4,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.billing_country',
                        type: "select",
                        name: "billing_country",
                        optional: true,
                        options: CountryHelper.getCountries(),
                        customFeedback: ClientFormError,
                        formErrorName: "billing_country"
                    }
                ]
            });

            if (settings.fields.delivery_address) {
                fields.push({
                    type: "col",
                    md: 12,
                    fields: [
                        {
                            title: 'CMS.Client.Content.Form.delivery_equal',
                            type: "switch",
                            name: "delivery_equal",
                            optional: true,
                            customFeedback: ClientFormError,
                            formErrorName: "delivery_equal",
                            onChange: e => {
                                document.querySelectorAll('.delivery-address').forEach(el => {
                                    el.classList.remove('visually-hidden');
                                    if (e.target.checked) {
                                        el.classList.add('visually-hidden');
                                    }
                                });
                            }
                        }
                    ]
                });
                fields.push({
                    type: "col",
                    md: 6,
                    className: 'delivery-address ' + (model.delivery_equal ? 'visually-hidden' : ''),
                    fields: [
                        {
                            title: 'CMS.Client.Content.Form.delivery_street',
                            type: "text",
                            name: "delivery_street",
                            optional: true,
                            customFeedback: ClientFormError,
                            formErrorName: "delivery_street"
                        }
                    ]
                });
                fields.push({
                    type: "col",
                    md: 3,
                    className: 'delivery-address ' + (model.delivery_equal ? 'visually-hidden' : ''),
                    fields: [
                        {
                            title: 'CMS.Client.Content.Form.delivery_number',
                            type: "text",
                            name: "delivery_number",
                            optional: true,
                            customFeedback: ClientFormError,
                            formErrorName: "delivery_number"
                        }
                    ]
                });
                fields.push({
                    type: "col",
                    md: 3,
                    className: 'delivery-address ' + (model.delivery_equal ? 'visually-hidden' : ''),
                    fields: [
                        {
                            title: 'CMS.Client.Content.Form.delivery_box',
                            type: "text",
                            name: "delivery_box",
                            optional: true,
                            customFeedback: ClientFormError,
                            formErrorName: "delivery_box"
                        }
                    ]
                });
                fields.push({
                    type: "col",
                    md: 4,
                    className: 'delivery-address ' + (model.delivery_equal ? 'visually-hidden' : ''),
                    fields: [
                        {
                            title: 'CMS.Client.Content.Form.delivery_postal',
                            type: "text",
                            name: "delivery_postal",
                            optional: true,
                            customFeedback: ClientFormError,
                            formErrorName: "delivery_postal"
                        }
                    ]
                });
                fields.push({
                    type: "col",
                    md: 4,
                    className: 'delivery-address ' + (model.delivery_equal ? 'visually-hidden' : ''),
                    fields: [
                        {
                            title: 'CMS.Client.Content.Form.delivery_city',
                            type: "text",
                            name: "delivery_city",
                            optional: true,
                            customFeedback: ClientFormError,
                            formErrorName: "delivery_city"
                        }
                    ]
                });
                fields.push({
                    type: "col",
                    md: 4,
                    className: 'delivery-address ' + (model.delivery_equal ? 'visually-hidden' : ''),
                    fields: [
                        {
                            title: 'CMS.Client.Content.Form.delivery_country',
                            type: "select",
                            name: "delivery_country",
                            optional: true,
                            options: CountryHelper.getCountries(),
                            customFeedback: ClientFormError,
                            formErrorName: "delivery_country"
                        }
                    ]
                });
            }
        }

        if (settings.fields.birth_date) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.birth_date',
                        type: "date",
                        name: "birth_date",
                        optional: true,
                        customFeedback: ClientFormError,
                        formErrorName: "birth_date"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] });
        }

        if (settings.fields.gender) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.gender',
                        type: "select",
                        name: "gender",
                        optional: true,
                        options: ['m', 'f'].map(item => { return { value: item, label: <FormattedMessageString id={'CMS.Client.Content.Form.gender.' + item} /> } }),
                        customFeedback: ClientFormError,
                        formErrorName: "gender"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] });
        }

        if (settings.fields.national_insurance_number) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.national_insurance_number',
                        type: "text",
                        name: "national_insurance_number",
                        optional: true,
                        customFeedback: ClientFormError,
                        formErrorName: "national_insurance_number"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] });
        }

        if (settings.membership_webshop_discount_code) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.webshop_discount_code',
                        type: "text",
                        optional: true,
                        name: "webshop_discount_code",
                        customFeedback: ClientFormError,
                        formErrorName: "webshop_discount_code"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] });
        }

        if (settings.fields.bank_details) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.iban',
                        type: "text",
                        name: "iban",
                        optional: true,
                        customFeedback: ClientFormError,
                        formErrorName: "iban"
                    }
                ]
            });
            fields.push({
                type: "col",
                md: 6,
                fields: [{
                    title: 'CMS.Client.Content.Form.bic',
                    type: "text",
                    name: "bic",
                    optional: true,
                    customFeedback: ClientFormError,
                    formErrorName: "bic"
                }]
            });
        }

        if (model.id) {
            fields.push({
                type: "col",
                md: 12,
                fields: [
                    {
                        title: 'CMS.Client.Content.Form.consent_1',
                        type: "switch",
                        name: "consent_1",
                        customFeedback: ClientFormError,
                        formErrorName: "consent_1",
                        disabled: true
                    }
                ]
            })

            if (settings.show_additional_gdpr_consent_1) {
                fields.push({
                    type: "col",
                    md: 12,
                    fields: [
                        {
                            title: settings.additional_gdpr_consent_1_text,
                            type: "switch",
                            name: "consent_2",
                            optional: true,
                            customFeedback: ClientFormError,
                            formErrorName: "consent_2",
                            disabled: true
                        }
                    ]
                })
            }

            if (settings.show_additional_gdpr_consent_2) {
                fields.push({
                    type: "col",
                    md: 12,
                    fields: [
                        {
                            title: settings.additional_gdpr_consent_2_text,
                            type: "switch",
                            name: "consent_3",
                            optional: true,
                            customFeedback: ClientFormError,
                            formErrorName: "consent_3",
                            disabled: true
                        }
                    ]
                })
            }
        }

        return [{
            type: "row",
            children: fields
        }];
    }
}

export default ClientTextFormComponent;