import moment from "moment";
import * as Yup from "yup"

export default class EventRegistration {
    event;
    client;
    attendee;

    id;
    hash;
    status;
    paid_at;
    price;
    nr_days;
    created_at;

    static schema = (settings) => {
        let fields = {
            event: Yup.string().required('CMS.Event.registration.Content.Form.event|select'),
            client: Yup.string().required('CMS.Event.registration.Content.Form.client|select')
        };

        if (settings.use_attendees) {
            fields.attendee = Yup.string().required('CMS.Event.registration.Content.Form.attendee|select');
        }

        return Yup.object(fields);
    }

    static schema_edit = (model, data = {}) => {
        let maxDays = model.nr_days;
        if ('events' in data && model.event?.id) {
            let event = data.events.filter(e => e.value === model.event.id)[0];
            maxDays = event?.nr_days
        }

        let fields = {
            event: Yup.string().required('CMS.Event.registration.Content.Form.event'),
            nr_days: Yup.number().required('CMS.Event.registrations.edit.form.nr_days').min(1, 'CMS.Event.registrations.edit.form.nr_days|1|number_min').max(maxDays, 'CMS.Event.registrations.edit.form.nr_days|' + maxDays + '|number_max'),
        };
        return Yup.object(fields);
    }

    static fromJson = (json) => {
        let item = new EventRegistration();

        item.event = json.event;
        item.client = json.client;
        item.attendee = json.attendee

        item.id = json.id;
        item.hash = json.hash;
        item.status = json.status;
        item.paid_at = json.paid_at ? moment(json.paid_at) : null;
        item.price = json.price;
        item.nr_days = json.nr_days;

        item.created_at = moment(json.created_at);

        return item;
    }
}
