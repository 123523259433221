import React from "react";
import MediaHelper from "../../../helpers/MediaHelper";
import EventFormError from "../errors/EventFormError";
import Module from "../../../models/module/Module";
import StandardTextFormEditorComponent from "../../basics/form/StandardTextFormEditorComponent";
import MediaImageUploadComponent from "../../media/image/MediaImageUploadComponent";
import moment, { isMoment } from "moment";
import MediaContentUploadComponent from "../../media/content/MediaContentUploadComponent";
import FormattedMessageString from "../../basics/FormattedMessageString";
import MediaFileUploadComponent from "../../media/image/MediaFileUploadComponent";

class EventTextFormComponent {
    static fields(settings, form) {
        let fields = [
            {
                type: "col",
                md: 8,
                fields: [{
                    title: "CMS.Event.Content.Form.title",
                    type: "text",
                    name: 'title',
                    customFeedback: EventFormError,
                    formErrorName: "title"
                }]
            },
            {
                type: "col",
                md: 6,
                fields: [{
                    title: "CMS.Event.Content.Form.is_g_event",
                    type: "switch",
                    name: 'is_g_event',
                    customFeedback: EventFormError,
                    formErrorName: "is_g_event",
                }]
            },
            { type: "col", md: 12, fields: [] },
            settings.use_categories ?
                {
                    type: "col",
                    md: 6,
                    fields: [{
                        title: 'CMS.Event.Content.Form.category',
                        type: "select",
                        name: "category",
                        options: settings.references.map(item => {
                            if (item.children.length) return false;
                            return { value: item.id, label: item.depthLabel.join(' > ') };
                        }).filter(x => x !== false),
                        customFeedback: EventFormError,
                        formErrorName: "category"
                    }]
                } : {},
            { type: "col", md: 12, fields: [] }
        ];

        if (settings.use_references) {
            settings.reference_modules.forEach(data => {
                fields.push({
                    type: "col",
                    md: 6,
                    fields: [{
                        title: <FormattedMessageString id="CMS.Event.Content.Form.module" values={{ reference: data.module.title }} />,
                        type: "select",
                        name: ("module_references_" + data.module.id),
                        multi: true,
                        optional: true,
                        options: data.items.map(item => {
                            return { value: item.id, label: item.title }
                        }),
                        customFeedback: EventFormError,
                        formErrorName: ("module_references_" + data.module.id)
                    }]
                }, { type: "col", md: 6, fields: [] })
            });
        }

        if (settings.show_start_date) {
            fields.push({
                type: "col",
                md: 4,
                fields: [{
                    title: "CMS.Event.Content.Form.date_start",
                    type: settings.show_hours ? 'datetime' : 'date',
                    name: 'date_start',
                    customProps: !form.state.model.id ? { min: moment().format('YYYY-MM-DD') } : {},
                    customFeedback: EventFormError,
                    formErrorName: "date_start",
                    onChange: (e, value) => {
                        let model = form.state.model;

                        model.nr_days = model.calculateNrDays();
                        form.setState({ model: model });
                    }
                }]
            });
        }

        if (settings.show_end_date) {
            fields.push({
                type: "col",
                md: 4,
                fields: [{
                    title: "CMS.Event.Content.Form.date_end",
                    type: settings.show_hours ? 'datetime' : 'date',
                    name: 'date_end',
                    customProps: !form.state.model.id ? { min: moment().format('YYYY-MM-DD') } : {},
                    customFeedback: EventFormError,
                    formErrorName: "date_end",
                    onChange: (e, value) => {
                        let model = form.state.model;

                        model.nr_days = model.calculateNrDays();
                        form.setState({ model: model });
                    }
                }]
            }, {
                type: "col",
                md: 8,
                fields: [{
                    title: "CMS.Event.Content.Form.nr_days",
                    type: "number",
                    name: 'nr_days',
                    customProps: { min: 1 },
                    customFeedback: EventFormError,
                    formErrorName: "nr_days"
                }]
            },
                { type: "col", md: 12, fields: [] });
        }
        if (settings.use_attendees) {
            fields.push({
                type: "col",
                md: 4,
                fields: [{
                    title: "CMS.Event.Content.Form.bookable_from",
                    type: "datetime",
                    name: 'bookable_from',
                    optional: true,
                    customFeedback: EventFormError,
                    formErrorName: "bookable_from"
                }]
            }, {
                type: "col",
                md: 4,
                fields: [{
                    title: "CMS.Event.Content.Form.bookable_till",
                    type: "datetime",
                    name: 'bookable_till',
                    optional: true,
                    customFeedback: EventFormError,
                    formErrorName: "bookable_till"
                }]
            });
        }
        if (settings.show_location) {
            fields.push({
                type: "col",
                md: 6,
                fields: [{
                    title: "CMS.Event.Content.Form.location",
                    type: "text",
                    name: 'location',
                    customFeedback: EventFormError,
                    formErrorName: "location"
                }]
            },
                { type: "col", md: 12, fields: [] });
        }

        if (settings.show_rate) {
            fields.push({
                type: "col",
                md: 6,
                fields: [{
                    title: "CMS.Event.Content.Form.price",
                    type: "text",
                    name: 'price',
                    customFeedback: EventFormError,
                    formErrorName: "price",
                    customProps: { addOn: { name: 'currency' } }
                }]
            },
                { type: "col", md: 12, fields: [] });
        }

        if (settings.show_minimum_age) {
            fields.push({
                type: "col",
                md: 4,
                fields: [{
                    title: "CMS.Event.Content.Form.minimum_age",
                    type: "number",
                    name: 'minimum_age',
                    customFeedback: EventFormError,
                    customProps: { min: 0, max: 100 },
                    formErrorName: "minimum_age"
                }]
            });
        }

        if (settings.show_maximum_age) {
            fields.push({
                type: "col",
                md: 4,
                fields: [{
                    title: "CMS.Event.Content.Form.maximum_age",
                    type: "number",
                    name: 'maximum_age',
                    customFeedback: EventFormError,
                    customProps: { min: 0, max: 100 },
                    formErrorName: "maximum_age"
                }]
            },
                { type: "col", md: 12, fields: [] });
        }

        if (settings.show_summary) {
            fields.push({
                type: "col",
                md: 8,
                fields: [{
                    title: "CMS.Event.Content.Form.summary",
                    type: "textarea",
                    name: 'summary',
                    customFeedback: EventFormError,
                    formErrorName: "summary"
                }]
            });
        }
        if (!settings.content_with_blocks) {
            fields.push({
                type: "col",
                md: 8,
                fields: [],
                custom: StandardTextFormEditorComponent,
                customProps: { label: 'CMS.Event.Content.Form.content', placeholder: 'CMS.Event.Content.Form.content.placeholder' }
            });
        }

        if (settings.show_cover_image) {
            fields.push({
                type: "col",
                md: 10,
                fields: [],
                optional: true,
                custom: MediaImageUploadComponent,
                customProps: { label: 'CMS.Event.Content.Form.image', type: 'event_image', settings: settings, field: 'image', formErrorName: 'image' }
            });
        }
        if (settings.show_program_pdf) {
            fields.push({
                type: "col",
                md: 10,
                fields: [],
                optional: true,
                custom: MediaFileUploadComponent,
                customProps: { label: 'CMS.Event.Content.Form.pdf_document', type: 'event_file', settings: settings, field: 'pdf_document', formErrorName: 'pdf_document' }
            });
        }

        return [{
            type: "row",
            children: fields
        }];
    }

    static fields_block(settings, isEdit = false) {
        return [{
            type: "row",
            children: [
                settings.has_special_blocks ? {
                    type: "col",
                    xl: 9,
                    fields: [{
                        title: "CMS.Media.Content.block.form.type",
                        type: "select",
                        options: ['text', 'accordion'].map(item => { return { value: item, label: <FormattedMessageString id={'CMS.Page.Media.Content.block.title.' + item} /> } }),
                        name: "type",
                        optional: false,
                        fake: !isEdit,
                        beforeOnChange: settings.onBlockTypeChange
                    }]
                } : {},
                {
                    type: "col",
                    xl: 9,
                    fields: [{
                        title: "CMS.Media.Content.block.form.title",
                        type: "text",
                        name: "title",
                        optional: settings.titleOptional, // !settings.has_special_blocks,
                        fake: !isEdit
                    }]
                },
                {
                    type: "col",
                    xl: 9,
                    fields: [],
                    custom: StandardTextFormEditorComponent,
                    fake: !isEdit
                },
                {
                    type: "col",
                    xl: 11,
                    fields: [],
                    custom: MediaContentUploadComponent,
                    customProps: { type: 'event_block_image', title: "CMS.Event.Media.Content.title", dimensionPrefix: 'content_with_blocks_image', settings: settings },
                    fake: isEdit
                }
            ]
        }]
    }

    static async fields_image_before_submit(element, settings) {
        return new Promise(async (resolve, reject) => {
            let module = Module.get();
            let item = element.state.model;

            if (item.date_start && isMoment(item.date_start)) item.date_start = item.date_start.format(settings.show_hours ? 'YYYY-MM-DD HH:mm:00' : 'YYYY-MM-DD');
            if (item.date_end && isMoment(item.date_end)) item.date_end = item.date_end.format(settings.show_hours ? 'YYYY-MM-DD HH:mm:00' : 'YYYY-MM-DD');

            let type = module ? module.module.type : null;

            if (item.image !== null && item.image !== undefined && (typeof item.image !== 'string')) {
                item.image = await MediaHelper.uploadImage(item, 'image', 'event_image', type, item.id, null, item.title);
            }

            if (item.pdf_document !== null && item.pdf_document !== undefined && (typeof item.pdf_document !== 'string')) {
                item.pdf_document = await MediaHelper.uploadImage(item, 'pdf_document', 'event_file', type, item.id, null, item.title);
            }

            element.setState({ model: item }, () => resolve(true))
        })
    }

    static async fields_block_before_submit(element, type) {
        return new Promise(async (resolve, reject) => {
            let event_content = element.state.model
            let value = event_content.value;
            let value_content = value.content ? value.content : [];
            value_content = await Promise.all(value_content.map(async (item) => {
                if (item.image !== null && item.image !== undefined) {
                    item.image_src = await MediaHelper.uploadImage(item, 'image', type, 'event', element.props.id, null, item.title);
                    item.image = undefined;
                }
                return item;
            }))

            event_content.value.content = value_content;

            element.setState({ model: event_content }, () => resolve(true))
        })
    }
}

export default EventTextFormComponent;