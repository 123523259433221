import DynamicFieldValue from "../models/attendee/DynamicFieldValue";
import API from "../services/api";

export default class DynamicFieldValueRepository {
    static create = (data) => API.post('/attendee/dynamic-field/create', data);
    static update = (data) => API.post('/attendee/dynamic-field/update', data);
    static updateTree = (data) => API.post('/attendee/dynamic-field/update/order', { tree: data });
    static delete = (id) => API.delete('/attendee/dynamic-field/delete?id=' + id);

    static all = (fieldNumber) => API.get('/attendee/dynamic-field/all/' + fieldNumber).then(response => response.data.dynamicFieldValues.map(value => DynamicFieldValue.formJson(value.dynamicValue)));
    static get = (id) => API.get('/attendee/dynamic-field/details/' + id).then(response => DynamicFieldValue.formJson(response.data.DynamicFieldValue));
}