import React from "react";
import { injectIntl } from 'react-intl';
import OverviewFragment from "../fragments/basics/OverviewFragment";
import FormFragment from "../fragments/basics/FormFragment";
import UserRolesEnum from "../enums/UserRolesEnum";
import RoleRoute from "../components/basics/RoleRoute";
import Module from "../models/module/Module";
import FormHelper from "../helpers/FormHelper";
import GeneralSettingsHelper from "../helpers/GeneralSettingsHelper";
import WebsiteRepository from "../repository/WebsiteRepository";
import CategoryRepository from "../repository/CategoryRepository";
import TestimonialRepository from "../repository/TestimonialRepository";
import FaqRepository from "../repository/FaqRepository";
import MapRepository from "../repository/MapRepository";
import NewsRepository from "../repository/NewsRepository";
import MediaRepository from "../repository/MediaRepository";
import { AttendeeRepository, BlogRepository, EventRegistrationRepository, EventRepository, ProductRepository, ProjectRepository, TeamRepository, WeblinkRepository } from "../repository";
import { LoaderComponent } from "../components/basics/layout";
import ClientRepository from "../repository/ClientRepository";
import DynamicFieldValueRepository from "../repository/DynamicFieldValueRepository";

class CMSModulePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: localStorage.getItem('language'),
            module: Module.get(),
            roles: this.props.user.roles,
            user: this.props.user
        };
    }

    componentDidMount() {
        window.addEventListener('moduleChanged', this.reload.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener('moduleChanged', this.reload.bind(this));
    }

    reload = () => this.setState({ reload: true, module: Module.get() }, () => this.setState({ reload: false }));

    render() {
        if (this.state.reload) return <LoaderComponent />;
        if (!this.state.module) return <LoaderComponent />;

        let moduleSettings = Module.getSettings(this.state.module.module);
        let moduleContent = Module.getContent(this.state.language, this.state.module);

        return (
            <React.Fragment key={this.state.module.id}>
                {/** ATTENDEE Start */}
                <RoleRoute exact path={"/module/attendees/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Client.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.Client.overview.delete.title"}
                            delete_text_id={"CMS.Client.overview.delete.text"}
                            delete_promise={AttendeeRepository.delete}
                            promise={AttendeeRepository.all()}
                            slug={"attendees"} parent={this}
                            component_name={"attendees"} user={this.state.user}
                            extraButtons={moduleSettings.use_membership ? [
                                { path: '/module/attendees/' + Module.getId() + '/import', 'text': 'CMS.Attendee.overview.link.import', role: 'attendees' }
                            ] : []}
                        />
                    )}
                />
                <RoleRoute exact path={"/module/attendees/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={'attendees'} component_name={"attendees"}
                            user={this.props.user}
                        />
                    )}
                />
                <RoleRoute exact path={"/module/attendees/:module/edit/:id"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug="attendees"
                            component_name="attendees" user={this.props.user}
                            get_promise={AttendeeRepository.get(routeProps.match.params.id)} />)}
                />

                <RoleRoute exact path={"/module/attendees/:module/dynamic-field/:field_number"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleSettings['additional_dynamic_field_' + routeProps.match.params.field_number + '_title'] ? moduleSettings['additional_dynamic_field_' + routeProps.match.params.field_number + '_title'] : ''}
                            text_id={moduleSettings[['additional_dynamic_field_' + routeProps.match.params.field_number + '_text']] ? moduleSettings[['additional_dynamic_field_' + routeProps.match.params.field_number + '_text']] : ''}
                            add={!GeneralSettingsHelper.is()}
                            single_title_id={moduleSettings['additional_dynamic_field_' + routeProps.match.params.field_number + '_title'] ? moduleSettings['additional_dynamic_field_' + routeProps.match.params.field_number + '_title'] : ''}
                            delete_title_id="CMS.Client.overview.delete.title"
                            delete_text_id="CMS.Client.overview.delete.text"
                            delete_promise={DynamicFieldValueRepository.delete}
                            promise={DynamicFieldValueRepository.all(routeProps.match.params.field_number)}
                            modal_title={this.props.intl.formatMessage({ id: "CMS.Form.overview.add.text" }, { text: moduleSettings['additional_dynamic_field_' + routeProps.match.params.field_number + '_title'] ? moduleSettings['additional_dynamic_field_' + routeProps.match.params.field_number + '_title'] : '' })}
                            slug="attendee-dynamic-fields" parent={this}
                            component_name="attendee-dynamic-fields" user={this.state.user}
                        />
                    )}
                />
                <RoleRoute exact path={"/module/attendees/:module/import"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps} title_id="CMS.Attendee.import.title" single_title_id={moduleContent.title} text_id="CMS.Attendee.import.text"
                            slug="attendees" component_name="attendee-import"
                            user={this.props.user}
                        />)}
                />
                {/** ATTENDEE End */}

                {/** BLOG Start */}
                <RoleRoute exact path={"/module/blog/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Blog.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.Blog.overview.delete.title"}
                            delete_text_id={"CMS.Blog.overview.delete.text"}
                            delete_promise={BlogRepository.delete}
                            promise={BlogRepository.all()}
                            slug={"blog"} parent={this}
                            component_name={"blog"} user={this.state.user} />)} />
                <RoleRoute exact path={"/module/blog/:module/subscriptions"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id="CMS.Blog.Subscription.overview.title"
                            text_id="CMS.Blog.Subscription.overview.text"
                            add={false}
                            single_title_id="CMS.Blog.Subscription.overview.single.title"
                            delete_title_id="CMS.Blog.Subscription.overview.delete.title"
                            delete_text_id={"CMS.Blog.Subscription.overview.delete.text"}
                            delete_promise={BlogRepository.deleteSubscription}
                            promise={BlogRepository.subscriptions()}
                            slug={"blog-subscription"} parent={this}
                            component_name={"blog-subscription"} user={this.state.user}
                            goBack={'/module/blog/' + routeProps.match.params.module + '/overview'}
                        />
                    )}
                />
                <RoleRoute exact path={"/module/blog/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={'blog'} component_name={"blog"}
                            user={this.props.user} />)} />
                <RoleRoute exact path={"/module/blog/:module/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={"blog"}
                            component_name={"blog"} user={this.props.user}
                            get_promise={BlogRepository.get(routeProps.match.params.id)} />)} />
                <RoleRoute exact path={"/module/blog/:module/content/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={"blog"}
                            component_name={"blog-content"} user={this.props.user}
                            get_promise={BlogRepository.get(routeProps.match.params.id)} />)} />
                <RoleRoute exact path={"/module/blog/:module/photoalbum/:id"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={"CMS.Blog.photoalbum.title"}
                            slug={"blog"}
                            full={true}
                            component_name={"blog-photo-album"} user={this.props.user}
                            delete_title_id={"CMS.Blog.photoalbum.delete.title"}
                            delete_text_id={"CMS.Blog.photoalbum.delete.text"}
                            text_id={"CMS.Blog.photoalbum.text"}
                            delete_func={FormHelper.fakeDelete}
                            type={"photo_album"}
                            get_promise={MediaRepository.getAlbum(routeProps.match.params.id, 'photo_album', 'blog')} />)} />
                <RoleRoute exact path={"/module/blog/:module/videoalbum/:id/:language"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={"CMS.Blog.videoalbum.title"}
                            text_id={"CMS.Blog.videoalbum.text"}
                            single_title_id={"CMS.Blog.videoalbum.title.add"}
                            overview_title="video_album"
                            promise={BlogRepository.getBackgroundVideos(routeProps.match.params.id, 'video_album_' + routeProps.match.params.language)}
                            slug={'video_album_' + routeProps.match.params.language}
                            component_name={"media-background-videos"}
                            delete_title_id={"CMS.Blog.videoalbum.delete.title"}
                            delete_text_id={"CMS.Blog.videoalbum.delete.text"}
                            delete_promise={WebsiteRepository.deleteMedia}
                            modal_title={"CMS.Media.Album.videoalbum.modal.title"}
                            user={this.state.user}
                            parent={this}
                            goBack={'/module/blog/' + routeProps.match.params.module + '/overview'}
                        />
                    )} />
                {/** BLOG End */}

                {/** CLIENT Start */}
                <RoleRoute exact path={"/module/clients/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Client.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.Client.overview.delete.title"}
                            delete_text_id={"CMS.Client.overview.delete.text"}
                            delete_promise={ClientRepository.delete}
                            promise={ClientRepository.all()}
                            slug={"clients"} parent={this}
                            component_name={"clients"} user={this.state.user}
                            extraButtons={moduleSettings.use_membership ? [
                                { path: '/module/clients/' + Module.getId() + '/import', 'text': 'CMS.Client.overview.link.import', role: 'clients' }
                            ] : []}
                        />
                    )}
                />
                <RoleRoute exact path={"/module/clients/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={'clients'} component_name={"clients"}
                            user={this.props.user}
                        />
                    )}
                />
                <RoleRoute exact path={"/module/clients/:module/edit/:id"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={"clients"}
                            component_name={"clients"} user={this.props.user}
                            get_promise={ClientRepository.get(routeProps.match.params.id)} />)}
                />
                <RoleRoute exact path={"/module/clients/:module/import"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps} title_id="CMS.Client.import.title" single_title_id={moduleContent.title} text_id="CMS.Client.import.text"
                            slug="clients" component_name="client-import"
                            user={this.props.user}
                        />)}
                />
                {/** CLIENT End */}

                {/** TESTIMONIALS Start */}
                <RoleRoute exact path={"/module/testimonials/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Testimonials.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.Testimonials.overview.delete.title"}
                            delete_text_id={"CMS.Testimonials.overview.delete.text"}
                            delete_promise={TestimonialRepository.delete}
                            promise={TestimonialRepository.getRows()}
                            slug={"testimonials"} parent={this}
                            component_name={"testimonials"} user={this.state.user} />)} />
                <RoleRoute exact path={"/module/testimonials/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={'testimonials'} component_name={"testimonials"}
                            user={this.props.user} />)} />
                <RoleRoute exact path={"/module/testimonials/:module/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={"testimonials"}
                            component_name={"testimonials-edit"} user={this.props.user}
                            get_promise={TestimonialRepository.get(routeProps.match.params.id)} />)} />
                {/** TESTIMONIALS End */}

                {/** FAQ Start */}
                <RoleRoute exact path={"/module/faqs/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Faq.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.Faq.overview.delete.title"}
                            delete_text_id={"CMS.Faq.overview.delete.text"}
                            delete_promise={FaqRepository.delete}
                            promise={FaqRepository.all()}
                            slug={"faqs"} parent={this}
                            component_name={"faqs"} user={this.state.user} />)} />
                <RoleRoute exact path={"/module/faqs/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={'faqs'} component_name={"faqs"}
                            user={this.props.user} />)} />
                <RoleRoute exact path={"/module/faqs/:module/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={"faqs"}
                            component_name={"faqs-edit"} user={this.props.user}
                            get_promise={FaqRepository.get(routeProps.match.params.id)} />)} />
                {/** FAQ End */}

                {/** CATEGORIES Start */}
                <RoleRoute exact path={"/module/categories/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Categories.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.Categories.overview.delete.title"}
                            delete_text_id={"CMS.Categories.overview.delete.text"}
                            delete_promise={CategoryRepository.delete}
                            promise={CategoryRepository.all()}
                            settingCheck={['manager_can_change']}
                            slug={"categories"} parent={this}
                            component_name={"categories"} user={this.state.user} />)} />
                <RoleRoute path={"/module/categories/:module/add/:parent?"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={'categories'} component_name="categories"
                            user={this.props.user} />)} />
                <RoleRoute exact path={"/module/categories/:module/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug="categories"
                            component_name="categories-edit" user={this.props.user}
                            get_promise={CategoryRepository.get(routeProps.match.params.id)} />)} />
                <RoleRoute exact path={"/module/categories/:module/content/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={"categories"}
                            component_name={"categories-content"} user={this.props.user}
                            get_promise={CategoryRepository.get(routeProps.match.params.id)} />)} />
                <RoleRoute exact path={"/module/categories/:module/photoalbum/:id"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={"CMS.Categories.photoalbum.title"}
                            slug={"categories"}
                            full={true}
                            component_name={"categories-photo-album"} user={this.props.user}
                            delete_title_id={"CMS.Categories.photoalbum.delete.title"}
                            delete_text_id={"CMS.Categories.photoalbum.delete.text"}
                            text_id={"CMS.Categories.photoalbum.text"}
                            delete_func={FormHelper.fakeDelete}
                            type={"photo_album"}
                            get_promise={CategoryRepository.getAlbum(routeProps.match.params.id, 'photo_album')} />)} />
                <RoleRoute exact path={"/module/categories/:module/videoalbum/:id/:language"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={"CMS.Categories.videoalbum.title"}
                            text_id={"CMS.Categories.videoalbum.text"}
                            single_title_id={"CMS.Categories.videoalbum.title.add"}
                            promise={CategoryRepository.getBackgroundVideos(routeProps.match.params.id, 'video_album_' + routeProps.match.params.language)}
                            slug={'video_album_' + routeProps.match.params.language}
                            component_name={"media-background-videos"}
                            delete_title_id={"CMS.Categories.videoalbum.delete.title"}
                            delete_text_id={"CMS.Categories.videoalbum.delete.text"}
                            delete_promise={WebsiteRepository.deleteMedia}
                            modal_title={"CMS.Media.Album.videoalbum.modal.title"}
                            user={this.state.user}
                            parent={this}
                            goBack={'/module/categories/' + routeProps.match.params.module + '/overview'}
                        />
                    )} />
                {/** CATEGORIES End */}

                {/** MAPS Start */}
                <RoleRoute exact path={"/module/maps/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Testimonials.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.Maps.overview.delete.title"}
                            delete_text_id={"CMS.Maps.overview.delete.text"}
                            delete_promise={MapRepository.delete}
                            promise={MapRepository.getAll()}
                            slug={"maps"} parent={this}
                            component_name={"maps"} user={this.state.user} />)} />
                <RoleRoute exact path={"/module/maps/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={'maps'} component_name={"maps"}
                            user={this.props.user} />)} />
                <RoleRoute exact path={"/module/maps/:module/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={"maps"}
                            component_name={"maps"} user={this.props.user}
                            get_promise={MapRepository.get(routeProps.match.params.id)} />)} />
                {/** MAPS End */}

                {/** NEWS Start */}
                <RoleRoute exact path={"/module/news/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.News.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.News.overview.delete.title"}
                            delete_text_id={"CMS.News.overview.delete.text"}
                            delete_promise={NewsRepository.delete}
                            promise={NewsRepository.all()}
                            slug={"news"} parent={this}
                            component_name={"news"} user={this.state.user} />)} />
                <RoleRoute exact path={"/module/news/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={'news'} component_name={"news"}
                            user={this.props.user} />)} />
                <RoleRoute exact path={"/module/news/:module/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={"news"}
                            component_name={"news"} user={this.props.user}
                            get_promise={NewsRepository.get(routeProps.match.params.id)} />)} />
                <RoleRoute exact path={"/module/news/:module/photoalbum/:id"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={"CMS.News.photoalbum.title"}
                            slug={"news"}
                            full={true}
                            component_name={"news-photo-album"} user={this.props.user}
                            delete_title_id={"CMS.News.photoalbum.delete.title"}
                            delete_text_id={"CMS.News.photoalbum.delete.text"}
                            text_id={"CMS.News.photoalbum.text"}
                            delete_func={FormHelper.fakeDelete}
                            type={"photo_album"}
                            get_promise={MediaRepository.getAlbum(routeProps.match.params.id, 'photo_album', 'news')} />)} />
                <RoleRoute exact path={"/module/news/:module/videoalbum/:id/:language"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={"CMS.News.videoalbum.title"}
                            text_id={"CMS.News.videoalbum.text"}
                            single_title_id={"CMS.News.videoalbum.title.add"}
                            overview_title="video_album"
                            promise={NewsRepository.getBackgroundVideos(routeProps.match.params.id, 'video_album_' + routeProps.match.params.language)}
                            slug={'video_album_' + routeProps.match.params.language}
                            component_name={"media-background-videos"}
                            delete_title_id={"CMS.News.videoalbum.delete.title"}
                            delete_text_id={"CMS.News.videoalbum.delete.text"}
                            delete_promise={WebsiteRepository.deleteMedia}
                            modal_title={"CMS.Media.Album.videoalbum.modal.title"}
                            user={this.state.user}
                            parent={this}
                            goBack={'/module/news/' + routeProps.match.params.module + '/overview'}
                        />
                    )} />
                {/** NEWS End */}

                {/** PROJECTS Start */}
                <RoleRoute exact path={"/module/projects/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Project.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.Project.overview.delete.title"}
                            delete_text_id={"CMS.Project.overview.delete.text"}
                            delete_promise={ProjectRepository.delete}
                            promise={ProjectRepository.all()}
                            slug={"projects"} parent={this}
                            component_name={"projects"} user={this.state.user} />)} />
                <RoleRoute exact path={"/module/projects/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={'projects'} component_name={"projects"}
                            user={this.props.user} />)} />
                <RoleRoute exact path={"/module/projects/:module/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={"projects"}
                            component_name={"projects"} user={this.props.user}
                            get_promise={ProjectRepository.get(routeProps.match.params.id)} />)} />
                <RoleRoute exact path={"/module/projects/:module/photoalbum/:id"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={"CMS.Project.photoalbum.title"}
                            slug={"projects"}
                            full={true}
                            component_name={"projects-photo-album"} user={this.props.user}
                            delete_title_id={"CMS.Project.photoalbum.delete.title"}
                            delete_text_id={"CMS.Project.photoalbum.delete.text"}
                            text_id={"CMS.Project.photoalbum.text"}
                            delete_func={FormHelper.fakeDelete}
                            type={"photo_album"}
                            get_promise={MediaRepository.getAlbum(routeProps.match.params.id, 'photo_album', 'project')} />)} />
                <RoleRoute exact path={"/module/projects/:module/videoalbum/:id/:language"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={"CMS.Project.videoalbum.title"}
                            text_id={"CMS.Project.videoalbum.text"}
                            single_title_id={"CMS.Project.videoalbum.title.add"}
                            overview_title="video_album"
                            promise={ProjectRepository.getBackgroundVideos(routeProps.match.params.id, 'video_album_' + routeProps.match.params.language)}
                            slug={'video_album_' + routeProps.match.params.language}
                            component_name={"media-background-videos"}
                            delete_title_id={"CMS.Project.videoalbum.delete.title"}
                            delete_text_id={"CMS.Project.videoalbum.delete.text"}
                            delete_promise={WebsiteRepository.deleteMedia}
                            modal_title={"CMS.Media.Album.videoalbum.modal.title"}
                            user={this.state.user}
                            parent={this}
                            goBack={'/module/projects/' + routeProps.match.params.module + '/overview'}
                        />
                    )} />
                {/** PROJECTS End */}

                {/** TEAMS Start */}
                <RoleRoute exact path={"/module/teams/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Team.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.Project.overview.delete.title"}
                            delete_text_id={"CMS.Project.overview.delete.text"}
                            delete_promise={TeamRepository.delete}
                            promise={TeamRepository.all()}
                            slug={"teams"} parent={this}
                            component_name={"teams"} user={this.state.user} />)} />
                <RoleRoute exact path={"/module/teams/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={'teams'} component_name={"teams"}
                            user={this.props.user} />)} />
                <RoleRoute exact path={"/module/teams/:module/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={"teams"}
                            component_name={"teams"} user={this.props.user}
                            get_promise={TeamRepository.get(routeProps.match.params.id)} />)} />
                {/** TEAMS End */}

                {/** WEBLINKS Start */}
                <RoleRoute exact path={"/module/weblinks/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Weblink.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.Project.overview.delete.title"}
                            delete_text_id={"CMS.Project.overview.delete.text"}
                            delete_promise={WeblinkRepository.delete}
                            promise={WeblinkRepository.all()}
                            slug={"weblinks"} parent={this}
                            component_name={"weblinks"} user={this.state.user} />)} />
                <RoleRoute exact path={"/module/weblinks/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={'weblinks'} component_name={"weblinks"}
                            user={this.props.user} />)} />
                <RoleRoute exact path={"/module/weblinks/:module/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug={"weblinks"}
                            component_name={"weblinks"} user={this.props.user}
                            get_promise={WeblinkRepository.get(routeProps.match.params.id)} />)} />

                {/** WEBLINKS End */}

                {/** EVENT Start */}
                <RoleRoute exact path={"/module/events/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Event.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id="CMS.Event.overview.delete.title"
                            delete_text_id="CMS.Event.overview.delete.text"
                            delete_promise={EventRepository.delete}
                            promise={EventRepository.all()}
                            slug="events" parent={this}
                            component_name="events" user={this.state.user} />)} />
                <RoleRoute exact path={"/module/events/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug="events" component_name="events"
                            user={this.props.user} />
                    )}
                />
                <RoleRoute exact path={"/module/events/:module/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            slug="events" single_title_id={moduleContent.title}
                            component_name="events" user={this.props.user}
                            get_promise={EventRepository.get(routeProps.match.params.id)}
                        />
                    )}
                />
                <RoleRoute exact path={"/module/events/:module/duplicate/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            slug="events" single_title_id={moduleContent.title}
                            component_name="events" user={this.props.user}
                            get_promise={EventRepository.get(routeProps.match.params.id)}
                        />
                    )}
                />
                <RoleRoute exact path={"/module/events/:module/content/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            slug="events" single_title_id={moduleContent.title}
                            component_name="event-content" user={this.props.user}
                            get_promise={EventRepository.get(routeProps.match.params.id)}
                        />
                    )}
                />
                <RoleRoute exact path={"/module/events/:module/photoalbum/:id"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={"CMS.Event.photoalbum.title"}
                            slug="events"
                            full={true}
                            component_name={"event-photo-album"} user={this.props.user}
                            delete_title_id={"CMS.Event.photoalbum.delete.title"}
                            delete_text_id={"CMS.Event.photoalbum.delete.text"}
                            text_id={"CMS.Event.photoalbum.text"}
                            delete_func={FormHelper.fakeDelete}
                            type={"photo_album"}
                            get_promise={MediaRepository.getAlbum(routeProps.match.params.id, 'photo_album', 'event')} />)} />
                <RoleRoute exact path={"/module/events/:module/videoalbum/:id/:language"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={"CMS.Event.videoalbum.title"}
                            text_id={"CMS.Event.videoalbum.text"}
                            single_title_id={"CMS.Event.videoalbum.title.add"}
                            overview_title="video_album"
                            promise={EventRepository.getBackgroundVideos(routeProps.match.params.id, 'video_album_' + routeProps.match.params.language)}
                            slug={'video_album_' + routeProps.match.params.language}
                            component_name={"media-background-videos"}
                            delete_title_id={"CMS.Event.videoalbum.delete.title"}
                            delete_text_id={"CMS.Event.videoalbum.delete.text"}
                            delete_promise={WebsiteRepository.deleteMedia}
                            modal_title={"CMS.Media.Album.videoalbum.modal.title"}
                            user={this.state.user}
                            parent={this}
                            goBack={'/module/events/' + routeProps.match.params.module + '/overview'}
                        />
                    )} />

                <RoleRoute exact path={"/module/events/:module/registrations/:event/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id="CMS.Event.registrations.overview.title" single_title_id="CMS.Event.registrations.overview.title_singular"
                            add={!GeneralSettingsHelper.is()} add_link={'/module/events/' + routeProps.match.params.module + '/registrations/' + routeProps.match.params.event + '/add'}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Event.overview.text'}
                            delete_title_id="CMS.Event.overview.delete.title" delete_text_id="CMS.Event.overview.delete.text" delete_promise={EventRegistrationRepository.delete}
                            promise={EventRegistrationRepository.all(routeProps.match.params.event ? routeProps.match.params.event : 0)}
                            slug="event-registrations" parent={this}
                            component_name="event-registrations" user={this.state.user}
                        />
                    )}
                />

                <RoleRoute exact path={"/module/events/:module/registrations/:event/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id="CMS.Event.registrations.overview.title_singular"
                            slug="events" component_name="event-registrations"
                            user={this.props.user}
                        />
                    )}
                />
                {/** EVENT End */}

                {/** PRODUCTS Start */}
                <RoleRoute exact path={"/module/products/:module/overview"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                    canShowInGeneralSettings={true}
                    render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id={moduleContent.title}
                            add={!GeneralSettingsHelper.is()}
                            text_id={moduleContent.description ? moduleContent.description : 'CMS.Product.overview.text'}
                            single_title_id={moduleContent.title}
                            delete_title_id={"CMS.Product.overview.delete.title"}
                            delete_text_id={"CMS.Product.overview.delete.text"}
                            delete_promise={ProductRepository.delete}
                            promise={ProductRepository.all()}
                            slug="products" parent={this}
                            component_name={"products"} user={this.state.user}
                            extraButtons={[
                                { path: '/module/products/' + Module.getId() + '/import', 'text': 'CMS.Product.overview.link.import', role: 'products' }
                            ]}
                        />)}
                />
                <RoleRoute exact path={"/module/products/:module/add"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps} single_title_id={moduleContent.title}
                            slug="products" component_name="products"
                            user={this.props.user}
                        />)}
                />
                <RoleRoute exact path={"/module/products/:module/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                    canShowInGeneralSettings={true}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id={moduleContent.title}
                            slug="products"
                            component_name="products" user={this.props.user}
                            get_promise={ProductRepository.get(routeProps.match.params.id)}
                        />)}
                />
                <RoleRoute exact path={"/module/products/:module/import"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles}
                    render={(routeProps) => (
                        <FormFragment {...routeProps} title_id="CMS.Product.import.title" single_title_id={moduleContent.title} text_id="CMS.Product.import.text"
                            slug="products" component_name="product-import"
                            user={this.props.user}
                        />)}
                />
                <RoleRoute exact path={"/module/products/:module/photoalbum/:id"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <FormFragment {...routeProps}
                            single_title_id="CMS.Product.photoalbum.title"
                            slug="products"
                            full={true}
                            component_name="product-photo-album" user={this.props.user}
                            delete_title_id="CMS.Product.photoalbum.delete.title"
                            delete_text_id="CMS.Product.photoalbum.delete.text"
                            text_id="CMS.Product.photoalbum.text"
                            delete_func={FormHelper.fakeDelete}
                            type={"photo_album"}
                            get_promise={MediaRepository.getAlbum(routeProps.match.params.id, 'photo_album', 'product')}
                        />)}
                />
                <RoleRoute exact path={"/module/products/:module/videoalbum/:id/:language"} role={UserRolesEnum.EDITOR}
                    roles={this.state.roles} render={(routeProps) => (
                        <OverviewFragment {...routeProps}
                            title_id="CMS.Product.videoalbum.title"
                            text_id="CMS.Product.videoalbum.text"
                            single_title_id="CMS.Product.videoalbum.title.add"
                            overview_title="video_album"
                            promise={ProductRepository.getBackgroundVideos(routeProps.match.params.id, 'video_album_' + routeProps.match.params.language)}
                            slug={'video_album_' + routeProps.match.params.language}
                            component_name="media-background-videos"
                            delete_title_id="CMS.Product.videoalbum.delete.title"
                            delete_text_id="CMS.Product.videoalbum.delete.text"
                            delete_promise={WebsiteRepository.deleteMedia}
                            modal_title="CMS.Media.Album.videoalbum.modal.title"
                            user={this.state.user}
                            parent={this}
                            goBack={'/module/products/' + routeProps.match.params.module + '/overview'}
                        />
                    )} />
                {/** PRODUCTS End */}
            </React.Fragment>
        )
    }

    // handleModuleChange = () => this.setState({ reload: true, module: Module.get() }, () => this.setState({ reload: false }));
}

export default injectIntl(CMSModulePage);