import React from "react";
import StandardTableComponent from "../../basics/table/StandardTableComponent";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { Form, ProgressBar, Button } from "react-bootstrap";
import prettyBytes from 'pretty-bytes';
import DeleteModalComponent from "../../basics/table/DeleteModalComponent";
import FormToastComponent from "../../basics/FormToastComponent";
import MediaRepository from "../../../repository/MediaRepository";
import PropTypes from "prop-types";
import Module from "../../../models/module/Module";
import MediaHelper from "../../../helpers/MediaHelper";
import { Link } from "react-router-dom";
import JsonHelper from "../../../helpers/JsonHelper";
import FormattedMessageString from "../../basics/FormattedMessageString";
import LoaderComponent from "../../basics/layout/LoaderComponent";

class MediaAlbumUploadComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            dropzoneDisabled: false,
            isLoading: true,
            files: []
        }
    }

    componentDidMount() {
        let id = this.props.id;
        let type = null;
        let componentName = this.props.component_name ? this.props.component_name : (this.props.parent ? this.props.parent.props.component_name : null);

        if (componentName && componentName.indexOf('pages') <= -1) {
            let module = Module.get();

            id = module.id;
            type = module.module.type;
        }

        MediaRepository.getSpecificSettings(this.props.type, id, type)
            .then(response => {
                if (response !== undefined && response.data !== undefined && response.data.settings !== undefined) {
                    let settings = response.data.settings;
                    this.setState({
                        settings: settings,
                        rows: [],
                        isLoading: false,
                    })
                } else {
                    FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found")
                }
            })
            .catch(error => FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found"))
    }

    _removeFailedUploadTimer = null;
    componentDidUpdate() {
        clearTimeout(this._removeFailedUploadTimer);
        this._removeFailedUploadTimer = setTimeout(this.removeFailedUploads, 3000);
    }

    removeFailedUploads = () => {
        let rows = this.state.rows;

        if (!rows.length) return;

        let newRows = []
        rows.forEach(row => {
            if (row.status.percent < 100 || !('uploading' in row.status)) {
                newRows.push(row);
            }
        });

        this.setState({ rows: newRows });
    }

    upload = () => this.setState({ dropzoneDisabled: true }, () => {

        if (this.state.rows.length > 0 && this.state.files.length > 0) {
            this._uploadCount = this.state.files.length;

            this.state.files.forEach((file, i) => setTimeout(() => {
                const options = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total)

                        let rows = [...this.state.rows];
                        rows = rows.map(row => {
                            if (row.id === file.id) {
                                return {
                                    ...row,
                                    status: {
                                        ...row.status,
                                        percent: percent,
                                        uploading: true
                                    }
                                }
                            }

                            return row;
                        })

                        this.setState(curr => ({ ...curr, rows: rows }));
                    },
                }

                let type = 'page';
                if (this.props.component_name && this.props.component_name.indexOf('pages') <= -1) {
                    let module = Module.get();
                    type = module.module.type;
                }

                MediaRepository.upload(file, this.props.type, type, this.props.id, options)
                    .then(response => {
                        if (response !== undefined && response.data !== undefined && response.data.media !== undefined) {
                            let media = [...this.props.parent.state.media, response.data.media];
                            this.props.parent.setState({ media: media, reloadOverview: true }, () => {
                                let rows = [...this.state.rows];
                                this.setState({
                                    rows: rows.map(row => {
                                        if (row.id === file.id) {
                                            return {
                                                ...row,
                                                status: { ...row.status, uploaded: true }
                                            }
                                        }
                                        return row;
                                    })
                                })

                                this.props.parent.setState({ reloadOverview: false })
                            })
                        } else {
                            FormToastComponent.errorTrans("CMS.Media.Album.file.not.found")
                        }
                    })
                    .catch(error => {
                        let rows = [...this.state.rows];

                        if (error.response !== undefined && error.response.data !== undefined) {
                            let error_message = JsonHelper.tryParse(error.response.data.message);

                            if (error_message !== false && error_message.file !== undefined) {
                                rows = rows.map(row => {
                                    if (row.id === file.id) {
                                        return {
                                            ...row,
                                            status: {
                                                ...row.status,
                                                errors: error_message.file,
                                                uploading: false
                                            }
                                        }
                                    }

                                    return row;
                                })
                                if (error_message.file.length > 0) {
                                    error_message.file.forEach(error => {
                                        FormToastComponent.error(file.name + " - " + error);
                                    })
                                } else {
                                    FormToastComponent.errorTrans("CMS.Media.Album.invalid.file")
                                }
                            } else {
                                rows = rows.map(row => {
                                    if (row.id === file.id) {
                                        return {
                                            ...row,
                                            status: {
                                                ...row.status,
                                                errors: error.response.data.message,
                                                uploading: false
                                            }
                                        }
                                    }

                                    return row;
                                })

                                FormToastComponent.errorTrans(error.response.data.message);
                            }
                        } else if (error.message) {
                            FormToastComponent.error(error.message);
                        } else {
                            FormToastComponent.errorTrans("CMS.Media.Album.invalid.config")
                        }

                        this.setState(curr => ({ ...curr, rows: rows, dropzoneDisabled: false }));
                    })
                    .finally(() => {
                        // uploading is done
                        let rows = [...this.state.rows];
                        let files = [...this.state.files];
                        files = files.filter(file => {
                            let uploaded = false;
                            rows.forEach(row => {
                                if (row.id === file.id && !uploaded && row.uploaded) {
                                    uploaded = true;
                                }
                            })

                            return uploaded;
                        })
                        rows = rows.filter(row => !row.status.uploaded);

                        this.setState(curr => ({ ...curr, rows: rows, files: files, dropzoneDisabled: false }));

                        if (this.props.customAfterUpload !== undefined) {
                            this.props.customAfterUpload();
                        }

                        this._uploadCount -= 1;

                        if (this._uploadCount <= 0) {
                            FormToastComponent.successTrans(<FormattedMessageString id={'CMS.Media.Album.' + this.props.type + '.upload.title'} />, "CMS.Media.Album." + this.props.type + ".upload.success")
                        }
                    })
            }, i * 500))
        } else {
            FormToastComponent.errorTrans('CMS.Media.Album.empty.upload');
            this.setState({ dropzoneDisabled: false })
        }
    });

    getTotalPercent() {
        if (this.state.rows.length > 0) {
            let total = 0
            this.state.rows.forEach(row => total += row.status.percent)

            return total / this.state.rows.length;
        }

        return 0;
    }

    getTotalSize() {
        if (this.state.rows.length > 0) {
            let total = 0;
            this.state.rows.forEach(row => total += row.size)

            return total;
        }

        return 0;
    }

    rowStyle = (row, rowIndex) => {
        const style = {};

        if (row.status.uploaded) {
            style.backgroundColor = "#86C13233";
        }

        if (row.status.errors !== undefined && row.status.errors.length > 0) {
            style.backgroundColor = "#FFC2CA";
        }

        return style;
    };

    onDropzoneClick = (e) => {
        e.preventDefault();

        let dropzone = document.querySelector('.form-control-file');
        if (!dropzone) return;

        dropzone.click();
    }

    render() {
        if (this.props.type === undefined) return 'type required';
        if (this.state.isLoading) return <LoaderComponent />;

        return (
            <div className="custom-data-table media-album mb-5">
                <StandardTableComponent keyField={"name"}
                    data={this.state.rows}
                    columns={[
                        {
                            dataField: "name",
                            text: <FormattedMessageString id="CMS.Media.Album.upload.name" />
                        },
                        {
                            dataField: "status",
                            text: <FormattedMessageString id="CMS.Media.Album.upload.status" />,
                            isDummyField: true,
                            style: {
                                width: 230
                            },
                            formatter: (cellContent, row) => {
                                return <div className="status">
                                    <ProgressBar now={row.status.percent} />
                                    <p className="status-text">{row.status.percent} %</p>
                                </div>
                            }
                        },
                        {
                            dataField: "size",
                            text: <FormattedMessageString id="CMS.Media.Album.upload.size" />,
                            sort: true,
                            style: {
                                width: 170
                            },
                            formatter: (cellContent, row) => {
                                return cellContent != null && Number.isInteger(cellContent) ? prettyBytes(cellContent) : <FormattedMessageString id="CMS.Media.Album.upload.dna" />
                            }
                        },
                        {
                            dataField: 'actions',
                            isDummyField: true,
                            text: '',
                            style: {
                                width: 60
                            },
                            formatter: (cellContent, row) => {
                                return <div className="actions">
                                    <Link to="#" className={row.status.uploading ? "delete disabled" : "delete"} onClick={(event) => {
                                        this.setState({
                                            files: this.state.files.filter((_row) => {
                                                return _row.id !== row.id;
                                            }),
                                            rows: this.state.rows.filter((_row) => {
                                                return _row.id !== row.id;
                                            })
                                        })
                                    }}>
                                        <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                    </Link>
                                </div>
                            }
                        }
                    ]}
                    title={"CMS.Media.Album." + this.props.type + ".upload.title"}
                    rowStyle={this.rowStyle}
                />
                <Dropzone
                    disabled={this.state.dropzoneDisabled}
                    multiple={true}
                    accept={MediaHelper.acceptedMimeTypes(this.state.settings)}
                    maxSize={this.state.settings?.max_size}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                        acceptedFiles.forEach(file => MediaHelper.handleDropAccepted(file, { minWidth: this.state.settings.min_width, minHeight: this.state.settings.min_height }, (result) => {
                            let last = this.state.rows[this.state.rows.length - 1];
                            let id = last !== undefined ? last.id + 1 : 1

                            if (this.state.settings !== undefined && this.state.settings.max_upload !== undefined) {
                                if (id > this.state.settings.max_upload) {
                                    return;
                                }
                            }

                            file.id = id;

                            let row = {
                                id: id,
                                name: file.name,
                                size: file.size,
                                status: {
                                    percent: 0,
                                    errors: []
                                }
                            }

                            let rows = [...this.state.rows, row];
                            let files = [...this.state.files, file];

                            this.setState({
                                rows: rows,
                                files: files
                            })
                        }))

                        MediaHelper.handleDropRejected(rejectedFiles, this.state.settings);
                    }}
                >
                    {({ getRootProps, getInputProps, acceptedFiles }) => {
                        return (
                            <section className={this.state.dropzoneDisabled ? "disabled media-drag-drop" : "media-drag-drop"} {...getRootProps()}>
                                <div className="media-drag-drop-inner">
                                    <div className="media-drag-drop-inner-row">
                                        <div className="media-drag-drop-icon">
                                            <div className="icon-wrapper">
                                                <FontAwesomeIcon icon={['fas', 'arrow-up']} />
                                            </div>
                                        </div>
                                        <div className="media-drag-drop-text">
                                            <FormattedMessage id="CMS.Media.Form.image_dragdrop.title">
                                                {(value) => <h1>{value}</h1>}
                                            </FormattedMessage>
                                            <FormattedMessage id={"CMS.Media.Form.images_dragdrop.title.sub"}>
                                                {(value) => <h2>{value}</h2>}
                                            </FormattedMessage>
                                        </div>
                                    </div>
                                    <div className={"media-drag-drop-requirements"}>
                                        <p className="mt-3">
                                            {MediaHelper.assembleRequirements(this.state.settings)}
                                        </p>
                                    </div>
                                    <Form.Control {...getInputProps()} />
                                </div>
                            </section>
                        );
                    }}
                </Dropzone>

                <div className="bottom">
                    <FormattedMessage id="CMS.Media.Album.add.more">
                        {(value) =>
                            <Link to="#" className="btn btn-outline-primary" onClick={this.onDropzoneClick}>
                                <FontAwesomeIcon className={"mr-2"} icon={['fal', 'plus']} />
                                {value}
                            </Link>
                        }
                    </FormattedMessage>
                    &nbsp;
                    <FormattedMessage id="CMS.Media.Album.start.upload">
                        {(value) =>
                            <Button onClick={() => this.upload()} className={this.state.dropzoneDisabled ? 'disabled' : ''}>
                                <FontAwesomeIcon className={"mr-2"} icon={['fal', 'upload']} />
                                {value}
                            </Button>
                        }
                    </FormattedMessage>
                    <div className="status">
                        <ProgressBar now={this.getTotalPercent()} />
                        <p className="status-text">{Math.round(this.getTotalPercent())} %</p>
                    </div>
                    <div className="total-size">
                        <p>{prettyBytes(this.getTotalSize())}</p>
                    </div>
                </div>

                <DeleteModalComponent parent={this} />
            </div>
        )
    }
}
MediaAlbumUploadComponent.propTypes = {
    customAfterUpload: PropTypes.func,
}
export default MediaAlbumUploadComponent;