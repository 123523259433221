import React from "react";
import PropTypes from "prop-types";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormattedMessageString from "../basics/FormattedMessageString";
import { FormattedMessage } from "react-intl";
import { ListGroup, Tab } from "react-bootstrap";
import ModalFormFragment from "../../fragments/basics/ModalFormFragment";
import WebsiteTranslationFormComponent from "./WebsiteTranslationFormComponent";
import DeleteConfirmComponent from "../basics/DeleteConfirmComponent";
import FormToastComponent from "../basics/FormToastComponent";
import Website from "../../models/website/Website";
import Application from "../../helpers/Application";
import { Link } from "react-router-dom";
import ContentHelper from "../../helpers/ContentHelper";
import LoaderComponent from "../basics/layout/LoaderComponent";
import { GeneralSettingsHelper } from "../../helpers";
const { SearchBar } = Search;

class WebsiteTranslationOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rowsArray: this.formatRows(),
            type: this.props.type,
            isLoading: true,
            isTableReloading: false,
            language: Application.tableLanguage()
        };

        this.columns = [
            {
                dataField: 'id',
                text: 'Id',
                hidden: true
            },
            {
                dataField: 'name',
                text: <FormattedMessageString id="CMS.Translation.overview.table.name" />,
                sort: true
            },
            {
                dataField: 'translation',
                text: <FormattedMessageString id="CMS.Translation.overview.table.translation" />,
                sort: true,
                formatter: (cellContent, row) => {
                    return <div dangerouslySetInnerHTML={{ __html: ContentHelper.replaceTags(cellContent) }}></div>;
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessageString id="CMS.Table.actions" />,
                formatter: (cellContent, row) => {
                    if (!row) return null;
                    return (
                        <div className="actions">
                            <Link to="#" className={"edit"} onClick={() => this.setState({ showEditModal: true, editTranslation: row })}>
                                <FontAwesomeIcon icon={['far', 'edit']} />
                            </Link>

                            {!GeneralSettingsHelper.is() && this.props.parent.checkRole('delete') && row.type !== 'default' ?
                                <Link to="#" className={"delete"} onClick={() => this.openDeleteModal(row)}>
                                    <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                </Link>
                                : null
                            }
                        </div>
                    )
                },
                style: { width: '76px' }
            }
        ];
    }

    componentDidMount() {
        this.setState({ isLoading: false })
    }

    /*componentDidUpdate() {
        if (this._searchBar?.input) {
            this._searchBar.input.dispatchEvent(new Event('change'));
        }
    }*/

    openDeleteModal = (row) => this.setState({ showDeleteModal: true, deleteRow: row })
    closeDeleteModal = () => this.setState({ showDeleteModal: false, deleteRow: null })

    deleteRow = (_row) => this.props.delete_func(Website.get(), _row.name)
        .then(response => {
            if (response.status === 200) {
                let rowsArray = this.state.rowsArray.map(function (language) {
                    language.rows.website = language.rows.website.filter(function (row) {
                        return row.name !== _row.name;
                    });
                    return language;
                });
                this.setState({ rowsArray: rowsArray }, () => {
                    this.closeDeleteModal()
                });
            } else {
                FormToastComponent.errorTrans('CMS.Table.delete.error');
            }
        })
        .catch(() => FormToastComponent.errorTrans('CMS.Table.delete.error'));

    table = (rowInfo, type) => {
        let options = {
            sizePerPage: Application.setting('table_number_rows', 100),
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            custom: true,
            totalSize: rowInfo.rows[type].length,
            prePageText: <FormattedMessageString id="CMS.Table.Pagination.prev" />,
            nextPageText: <FormattedMessageString id="CMS.Table.Pagination.next" />,
            page: Application.tablePageNumber(type),
            onPageChange: (page) => Application.updateTablePageNumber(page, type)
        };

        return (
            <div className="custom-data-table website-translation" key={type}>
                <ToolkitProvider keyField="id" bootstrap4
                    data={rowInfo.rows[type]}
                    columns={this.columns}
                    // search={true}
                    search={{ searchFormatted: true }}
                >
                    {props => (
                        <PaginationProvider pagination={paginationFactory(options)}>
                            {({ paginationProps, paginationTableProps }) => (
                                <div>
                                    <div className="table-header">
                                        <FormattedMessage id={this.props.title_id + '.' + type}>
                                            {(title) => <p>{title}</p>}
                                        </FormattedMessage>

                                        {props.baseProps.search && (
                                            <div className="custom-search-bar mr-4">
                                                <FontAwesomeIcon icon={['fas', 'search']} />
                                                <FormattedMessage id={'CMS.Websites.Translations.search.title.' + type}>
                                                    {title =>
                                                        <FormattedMessage id="CMS.Table.search.placeholder"
                                                            values={{ type: title }}>
                                                            {value => <SearchBar ref={ref => this._searchBar = ref} {...props.searchProps} placeholder={value} />}
                                                        </FormattedMessage>
                                                    }
                                                </FormattedMessage>
                                            </div>
                                        )}
                                    </div>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        {...paginationTableProps}
                                        wrapperClasses="table-responsive"
                                        sort={{ dataField: 'name', order: 'asc' }}
                                    />
                                    <PaginationListStandalone {...paginationProps} />
                                </div>
                            )}
                        </PaginationProvider>
                    )}
                </ToolkitProvider>
            </div>
        )
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id="list-group-languages"
                    defaultActiveKey={"#" + (this.state.rowsArray[this.state.language] !== undefined ? this.state.rowsArray[this.state.language].language : this.state.rowsArray[0].language)}>
                    <ListGroup>
                        {this.state.rowsArray.map((rowInfo, index) => (
                            <ListGroup.Item action href={"#" + rowInfo.language} key={index}
                                onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}>
                                {rowInfo.language}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>

                    <Tab.Content>
                        {this.state.rowsArray.map((rowInfo, index) => {
                            if (this.state.isTableReloading) return <LoaderComponent />;
                            return (
                                <Tab.Pane eventKey={"#" + rowInfo.language} key={index}>
                                    {this.table(rowInfo, 'website')}
                                    <div className="p-5"></div>
                                    {this.table(rowInfo, 'default')}
                                </Tab.Pane>
                            )
                        })}
                    </Tab.Content>
                </Tab.Container>

                <ModalFormFragment title_id={this.props.delete_title_id} show={this.state.showDeleteModal}
                    onHide={this._closeDeleteModal} is_delete={true}
                >
                    <DeleteConfirmComponent delete_text_id={this.props.delete_text_id}
                        onHide={this._closeDeleteModal}
                        delete_func={() => this.deleteRow(this.state.deleteRow)}
                    />
                </ModalFormFragment>

                <ModalFormFragment title_id="CMS.Websites.Translations.edit.title" show={this.state.showEditModal}
                    onHide={() => this.setState({ showEditModal: false, editTranslation: null })}
                >
                    <WebsiteTranslationFormComponent parent_ref={this} table_ref={this}
                        translation={this.state.editTranslation}
                    />
                </ModalFormFragment>
            </div>
        );
    }

    formatRows = () => {
        let newRows = [];

        this.props.rows.forEach(row => {
            let newRow = {
                language: row.language,
                language_code: row.language_code,
                rows: { default: [], website: [] }
            };

            row.rows.forEach(transRow => {
                newRow.rows[transRow.type].push(transRow);
            })

            newRows.push(newRow);
        })

        return newRows;
    }
}

WebsiteTranslationOverviewTableComponent.propTypes = {
    title_id: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    delete_title_id: PropTypes.string.isRequired,
    delete_text_id: PropTypes.string.isRequired,
    delete_func: PropTypes.func.isRequired,
    parent: PropTypes.any.isRequired
};

export default WebsiteTranslationOverviewTableComponent;