import API from "../services/api";
import EventRegistration from '../models/event/EventRegistration';

export default class EventRegistrationRepository {
    static all = (event = null) => API.get('/event/registration/all?event=' + event).then(response => response.data.registrations.map(registration => EventRegistration.fromJson(registration)));
    static get = (id) => API.get('/event/registration/details/' + id).then(response => EventRegistration.fromJson(response.data.registration));

    static create = (data) => API.post('/event/registration/create', data);
    static update = (data) => API.post('/event/registration/update', data).then(response => EventRegistration.fromJson(response.data.registration));
    static quickUpdate = (data) => API.post('/event/registration/quick-update', data).then(response => EventRegistration.formJson(response.data.registration));
    static delete = (id) => API.delete('/event/registration/delete?id=' + id);
}