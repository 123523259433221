import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import ModuleRepository from "../../repository/ModuleRepository";
import TeamFormError from './errors/TeamFormError';
import ModuleTypeEnum from "../../enums/ModuleTypeEnum";
import Module from "../../models/module/Module";

class TeamCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let lng = localStorage.getItem('language');
        if (this.props.parent.props.match.params.language) {
            lng = this.props.parent.props.match.params.language;
        }

        this.state = {
            use_categories: props.values.settings.use_categories,
            use_reference: props.values.settings.use_reference,
            show_function: props.values.settings.show_function,
            show_phone: props.values.settings.show_phone,
            show_mobile: props.values.settings.show_mobile,
            show_email: props.values.settings.show_email,
            show_additional_field_1: props.values.settings.show_additional_field_1,
            additional_field_1_type: props.values.settings.additional_field_1_type,
            additional_field_1_required: props.values.settings.additional_field_1_required,
            additional_field_1_label: props.values.settings.additional_field_1_label,
            show_additional_field_2: props.values.settings.show_additional_field_2,
            additional_field_2_type: props.values.settings.additional_field_2_type,
            additional_field_2_required: props.values.settings.additional_field_2_required,
            additional_field_2_label: props.values.settings.additional_field_2_label,
            show_additional_field_3: props.values.settings.show_additional_field_3,
            additional_field_3_type: props.values.settings.additional_field_3_type,
            additional_field_3_required: props.values.settings.additional_field_3_required,
            additional_field_3_label: props.values.settings.additional_field_3_label,
            show_content: props.values.settings.show_content,
            show_image: props.values.settings.show_image,
            image_width: props.values.settings.image_width,
            image_height: props.values.settings.image_height,
            categories: [],
            modules: [],
            lng: lng,
            additionFieldTypes: [{ key: 'text', label: 'Tekstveld' }, { key: 'url', label: 'URL-veld' }, { key: 'textarea', label: 'Groot tekstveld' }, { key: 'editor', label: 'WYSIWYG editor' }]
        }

        this.originalValues = this.props.values;
    }

    componentDidMount() {
        ModuleRepository.all()
            .then(result => this.setState({
                categories: result.filter(module => module.module.type === ModuleTypeEnum.CATEGORY),
                modules: Module.filterReferences(result),
                use_categories: this.props.values.settings.use_categories
            }));
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({
                currentEvent: { ...event }
            })
        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = () => {
        this.state.currentEvent.currentTarget.click();

        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id="CMS.Team.Form.settings">
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {/* Use categories */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-use-categories"
                                    label="use_categories"
                                    name="settings.use_categories"
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({ use_categories: !this.state.use_categories }, () => {
                                                delete this.props.values.settings.module;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.use_categories}
                                />
                            </Col>

                            {this.state.use_categories ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup className="large mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            id="inputGroupPrependEyecatcherWidth">module</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control as="select"
                                                        aria-describedby="module"
                                                        onChange={this.onChange}
                                                        name="settings.module"
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.module}
                                                        value={this.props.values.settings.module}
                                                    >
                                                        <option value="">-</option>
                                                        {this.state.categories.map(module => {
                                                            let moduleContent = module.module.contents.filter(content => content.language === this.state.lng)[0];
                                                            return <option value={module.id}>{moduleContent.title}</option>
                                                        })}
                                                    </Form.Control>

                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.module && (
                                                            <TeamFormError error={this.props.errors.settings.module} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.use_categories">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Use reference */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-use-reference"
                                    label="use_reference"
                                    name="settings.use_reference"
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({ use_reference: !this.state.use_reference }, () => {
                                                delete this.props.values.settings.reference_module;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.use_reference}
                                />
                            </Col>

                            {this.state.use_reference ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup className="large mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            id="inputGroupPrependEyecatcherWidth">module</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control as="select"
                                                        aria-describedby="reference_module"
                                                        onChange={this.onChange}
                                                        name="settings.reference_module"
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.reference_module}
                                                        value={this.props.values.settings.reference_module}
                                                    >
                                                        {this.state.modules.map(module => {
                                                            let moduleContent = module.module.contents.filter(content => content.language === this.state.lng)[0];
                                                            return <option value={module.id}>{moduleContent.title}</option>
                                                        })}
                                                    </Form.Control>

                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.reference_module && (
                                                            <TeamFormError error={this.props.errors.settings.reference_module} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.use_reference">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show function */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-function"
                                    label="show_function"
                                    name="settings.show_function"
                                    onChange={event => this.onChange(event, () => this.setState({ show_function: !this.state.show_function }))}
                                    className="mb-1"
                                    checked={this.state.show_function}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.show_function">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show phone */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-phone"
                                    label="show_phone"
                                    name="settings.show_phone"
                                    onChange={event => this.onChange(event, () => this.setState({ show_phone: !this.state.show_phone }))}
                                    className="mb-1"
                                    checked={this.state.show_phone}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.show_phone">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show mobile */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-mobile"
                                    label="show_mobile"
                                    name="settings.show_mobile"
                                    onChange={(event) => this.onChange(event, () => this.setState({ show_mobile: !this.state.show_mobile }))}
                                    className="mb-1"
                                    checked={this.state.show_mobile}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.show_mobile">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show email */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-email"
                                    label="show_email"
                                    name="settings.show_email"
                                    onChange={(event) => this.onChange(event, () => this.setState({ show_email: !this.state.show_email }))}
                                    className="mb-1"
                                    checked={this.state.show_email}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.show_email">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show additionalField 1 */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-additional-field-1"
                                    label="show_additional_field_1"
                                    name="settings.show_additional_field_1"
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({ show_additional_field_1: !this.state.show_additional_field_1 }, () => {
                                                delete this.props.values.settings.additional_field_1_type;
                                                delete this.props.values.settings.additional_field_1_required;
                                                delete this.props.values.settings.additional_field_1_label;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_additional_field_1 ? "checked" : ""}
                                />
                            </Col>

                            {this.state.show_additional_field_1 ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup className="large mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            id="inputGroupPrependEyecatcherWidth">type</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control as="select"
                                                        aria-describedby="additional_field_1_type"
                                                        onChange={this.onChange}
                                                        name="settings.additional_field_1_type"
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_field_1_type}
                                                        value={this.props.values.settings.additional_field_1_type}
                                                    >
                                                        {this.state.additionFieldTypes.map(type => (
                                                            <FormattedMessage id={type.label}>
                                                                {label => <option value={type.key}>{label}</option>}
                                                            </FormattedMessage>
                                                        ))}
                                                    </Form.Control>

                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.additional_field_1_type && (
                                                            <TeamFormError error={this.props.errors.settings.additional_field_1_type} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>

                                            <Col md={12}>
                                                <InputGroup className="xxl mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            id="inputGroupPrependEyecatcherWidth">label</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        aria-describedby="additional_field_1_label"
                                                        type="text"
                                                        name="settings.additional_field_1_label"
                                                        onChange={this.onChange}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_field_1_label}
                                                        value={this.props.values.settings.additional_field_1_label}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.additional_field_1_label && (
                                                            <TeamFormError error={this.props.errors.settings.additional_field_1_label} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch-show-additional-field-1-required"
                                                    label="required"
                                                    name="settings.additional_field_1_required"
                                                    onChange={(event) => {
                                                        this.onChange(event, () => this.setState({ additional_field_1_required: !this.state.additional_field_1_required }));
                                                    }}
                                                    className="mb-1"
                                                    checked={this.state.additional_field_1_required ? "checked" : ""}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}


                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.show_additional_field_1">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show additionalField 2 */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-additional-field-2"
                                    label="show_additional_field_2"
                                    name="settings.show_additional_field_2"
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({ show_additional_field_2: !this.state.show_additional_field_2 }, () => {
                                                delete this.props.values.settings.additional_field_2_type;
                                                delete this.props.values.settings.additional_field_2_required;
                                                delete this.props.values.settings.additional_field_2_label;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_additional_field_2 ? "checked" : ""}
                                />
                            </Col>

                            {this.state.show_additional_field_2 ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup className="large mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            id="inputGroupPrependEyecatcherWidth">type</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control as="select"
                                                        aria-describedby="additional_field_2_type"
                                                        onChange={this.onChange}
                                                        name="settings.additional_field_2_type"
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_field_2_type}
                                                        value={this.props.values.settings.additional_field_2_type}
                                                    >
                                                        {this.state.additionFieldTypes.map(type => (
                                                            <FormattedMessage id={type.label}>
                                                                {label => <option value={type.key}>{label}</option>}
                                                            </FormattedMessage>
                                                        ))}
                                                    </Form.Control>

                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.additional_field_2_type && (
                                                            <TeamFormError error={this.props.errors.settings.additional_field_2_type} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>

                                            <Col md={12}>
                                                <InputGroup className="xxl mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            id="inputGroupPrependEyecatcherWidth">label</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        aria-describedby="additional_field_2_label"
                                                        type="text"
                                                        name="settings.additional_field_2_label"
                                                        onChange={this.onChange}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_field_2_label}
                                                        value={this.props.values.settings.additional_field_2_label}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.additional_field_2_label && (
                                                            <TeamFormError error={this.props.errors.settings.additional_field_2_label} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch-show-additional-field-2-required"
                                                    label="required"
                                                    name="settings.additional_field_2_required"
                                                    onChange={(event) => {
                                                        this.onChange(event, () => this.setState({ additional_field_2_required: !this.state.additional_field_2_required }));
                                                    }}
                                                    className="mb-1"
                                                    checked={this.state.additional_field_2_required ? "checked" : ""}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}
                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.show_additional_field_2">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show additionalField 3 */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-additional-field-3"
                                    label="show_additional_field_3"
                                    name="settings.show_additional_field_3"
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({ show_additional_field_3: !this.state.show_additional_field_3 }, () => {
                                                delete this.props.values.settings.additional_field_3_type;
                                                delete this.props.values.settings.additional_field_3_required;
                                                delete this.props.values.settings.additional_field_3_label;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_additional_field_3 ? "checked" : ""}
                                />
                            </Col>

                            {this.state.show_additional_field_3 ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup className="large mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            id="inputGroupPrependEyecatcherWidth">type</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control as="select"
                                                        aria-describedby="additional_field_3_type"
                                                        onChange={this.onChange}
                                                        name="settings.additional_field_3_type"
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_field_3_type}
                                                        value={this.props.values.settings.additional_field_3_type}
                                                    >
                                                        {this.state.additionFieldTypes.map(type => (
                                                            <FormattedMessage id={type.label}>
                                                                {label => <option value={type.key}>{label}</option>}
                                                            </FormattedMessage>
                                                        ))}
                                                    </Form.Control>

                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.additional_field_3_type && (
                                                            <TeamFormError error={this.props.errors.settings.additional_field_3_type} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>

                                            <Col md={12}>
                                                <InputGroup className="xxl mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            id="inputGroupPrependEyecatcherWidth">label</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        aria-describedby="additional_field_3_label"
                                                        type="text"
                                                        name="settings.additional_field_3_label"
                                                        onChange={this.onChange}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_field_3_label}
                                                        value={this.props.values.settings.additional_field_3_label}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.additional_field_3_label && (
                                                            <TeamFormError error={this.props.errors.settings.additional_field_3_label} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch-show-additional-field-3-required"
                                                    label="required"
                                                    name="settings.additional_field_3_required"
                                                    onChange={(event) => {
                                                        this.onChange(event, () => this.setState({ additional_field_3_required: !this.state.additional_field_3_required }));
                                                    }}
                                                    className="mb-1"
                                                    checked={this.state.additional_field_3_required ? "checked" : ""}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.show_additional_field_3">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show content */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-content"
                                    label="show_content"
                                    name="settings.show_content"
                                    onChange={event => this.onChange(event, () => this.setState({ show_content: !this.state.show_content }))}
                                    className="mb-1"
                                    checked={this.state.show_content}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.show_content">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show image */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-image"
                                    label="show_image"
                                    name="settings.show_image"
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({
                                                show_image: !this.state.show_image
                                            }, () => {
                                                delete this.props.values.settings.image_width;
                                                delete this.props.values.settings.image_height;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_image ? "checked" : ""}
                                />
                            </Col>

                            {this.state.show_image ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={6}>
                                                <InputGroup className="mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            id="inputGroupPrependEyecatcherWidth">image_width</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        aria-describedby="image_width"
                                                        type="text"
                                                        name="settings.image_width"
                                                        onChange={this.onChange}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.image_width}
                                                        value={this.props.values.settings.image_width}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.image_width && (
                                                            <TeamFormError error={this.props.errors.settings.image_width} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>

                                            <Col md={6}>
                                                <InputGroup className="mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            id="inputGroupPrependEyecatcherHeight">image_height</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        aria-describedby="image_height"
                                                        type="text"
                                                        name="settings.image_height"
                                                        onChange={this.onChange}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.image_height}
                                                        value={this.props.values.settings.image_height}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.image_height && (
                                                            <TeamFormError error={this.props.errors.settings.image_height} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}


                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.show_image">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>

                <DeleteModalComponent parent={this} title="CMS.Team.Form.setting.delete.title" text="CMS.Team.Form.setting.delete.text"
                    buttons={{
                        confirm: {
                            text: 'Default.confirm',
                            icon: 'check'
                        }
                    }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={() => this.setState({ showDeleteModal: false, deleteRow: null })}
                />
            </Form.Group>
        );
    }
}

export default TeamCustomSettingsFormComponent;